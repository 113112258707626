<template>
  <b-card no-body>
    <b-card-header header-tag="nav">
      <b-nav card-header tabs justified>
        <b-nav-item :active="activeTab === 'chat'" @click="setActiveTab('chat')"><BIconChatDots/> Chat</b-nav-item>
        <b-nav-item :active="activeTab === 'notes'" @click="setActiveTab('notes')"><BIconFileText/> Notes</b-nav-item>
        <!--
        <b-nav-item-dropdown id="more" text="More" toggle-class="nav-link-custom" right>
          <b-dropdown-item @click="setActiveTab('appointments')">Create appointment</b-dropdown-item>
          <b-dropdown-item @click="setActiveTab('contact')">Create contact</b-dropdown-item>
          <b-dropdown-item @click="askForContact()">Ask for contact details</b-dropdown-item>
        </b-nav-item-dropdown>
        -->
      </b-nav>
    </b-card-header>

    <b-card-body>
      <Chat v-if="activeTab === 'chat' && chatGroupConfig"  :groupConfig="chatGroupConfig" :user="user"/>
      <Notes v-if="activeTab === 'notes'" :activeCall="activeCall"/>
      <!--
      <Contact v-if="activeTab === 'contact'"/>
      <Appointments v-if="activeTab === 'appointments'"/>
      -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BIconChatDots,
  BIconFileText
} from 'bootstrap-vue';
export default {
  props: ['activeCall'],
  components: {
    BIconChatDots,
    BIconFileText,
    Chat: () => import('@/common/components/Chat/Chat'),
    Notes: () => import('./Notes'),
    //Appointments: () => import('./Appointments'),
    //Contact: () => import('./Contact')
  },
  data(){
    return {
      activeTab: "chat",
      chatGroupConfig: null
    };
  },

  methods: {
    setActiveTab(tabName){
      this.activeTab = tabName;
    },
    askForContact(){
      console.log("notification was sent")
    }
  },

  mounted(){
    this.$api.post('chat/group',{
      data: {
        customGroupId: 'call-'+this.activeCall.client.callId,
        participants: [
          {
            type: "anonym",
            id: 'visitor-'+this.activeCall.client.visitorId
          }
        ]
      }
    })
    .then(() => {
      this.chatGroupConfig = {
        groupId: 'call-'+this.activeCall.client.callId,
        type: "call",
        options: {
          callId: this.activeCall.client.callId
        }
      }
    })
  },

  computed: {
    user(){
        return this.$store.getters['User/Profile/getProfile'];
    }
  }
};
</script>
